
export default [
  { type: 'item', title: 'Übersicht', icon: 'mdi-view-dashboard', route: { name: 'Dashboard' }, dashboard: false },
  {
    title: 'Touren',
    color: 'green',
    icon: 'mdi-calendar',
    route: { name: 'Events', params: { year: (new Date()).getFullYear() } },
    dashboard: true,
  },
  {
    title: 'Fotos',
    color: 'cyan',
    icon: 'mdi-image-multiple',
    route: { name: 'PhotoDashboard' },
    dashboard: true,
  },
  {
    title: 'Mitglieder',
    color: 'red',
    icon: 'mdi-account-group',
    route: { name: 'Members' },
    dashboard: true,
  },
  {
    title: 'Gäste',
    color: 'blue',
    icon: 'mdi-account-group-outline',
    route: { name: 'Guests' },
    dashboard: true,
  },
  {
    title: 'Logins',
    color: 'orange',
    icon: 'mdi-account-multiple',
    route: { name: 'Accounts' },
    dashboard: true,
  },
  {
    title: 'Email',
    color: 'teal',
    icon: 'mdi-email',
    route: { name: 'MailDashboard' },
    dashboard: true,
  },
  {
    title: 'Statistik',
    color: 'blue-grey lighten-1',
    icon: 'mdi-chart-timeline-variant',
    route: { name: 'Statistics' },
    dashboard: true,
  },
  {
    title: 'Tools',
    color: 'indigo',
    icon: 'mdi-wrench',
    route: { name: 'Tools' },
    dashboard: true,
  },

]
