<template>
  <application-layout
    title="SAC-Tourenverwaltung"
    :sideNavigation="sideNavigation"
  >
    <template v-slot:afterFooter>
      <v-dialog v-if="userIsAdmin" v-model="showUserSwitch" width="300">
        <user-switch-card @close="showUserSwitch = false" />
      </v-dialog>
    </template>
  </application-layout>
</template>

<script>
import { mapState } from 'pinia'
import { handleApiError } from '@/utils.js'

import navigationItems from '@/navigation'
import UserSwitchCard from '@/components/UserSwitchCard'
import ApplicationLayout from '@/components/ApplicationLayout.vue'

import { usePersonStore } from '@/stores/person'
import { useUserStore } from '@/stores/user'
import { useNotificationStore } from '@/stores/notification'
import { useEventStore } from '@/stores/event'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Main',
  components: { UserSwitchCard, ApplicationLayout },
  props: {},
  data() {
    return {
      drawer: false,
      showUserSwitch: false,
    }
  },
  computed: {
    sideNavigation() {
      const items = [
        // Main Navigation Items
        ...navigationItems.map(item => {
          return { ...item, props: { to: item.route, exact: true }, type: 'item' }
        }),
        // Profile Items
        { type: 'divider' },
        { type: 'header', title: 'Mein Profil' },
        { type: 'item', title: 'Einstellungen', icon: 'mdi-settings', props: { to: { name: 'Profile' } } },
        { type: 'item', title: 'Logout', icon: 'mdi-logout-variant', on: { click: () => { this.logout() } } },
        // Help Item
        { type: 'divider' },
        { type: 'item', title: 'Hilfe', icon: 'mdi-lifebuoy', props: { href: '/doc/', target: '_blank' } },
      ]

      if (this.userIsAdmin) {
        items.push(...[
          { type: 'divider' },
          { type: 'header', title: 'Admin Tools' },
          {
            type: 'item',
            title: 'User Wechsel',
            subtitle: `Aktuell: ${this.user.name}`,
            icon: 'mdi-wrench',
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            on: {
              click: () => { this.showUserSwitch = true },
            },
          },
          { type: 'item', title: 'Admin Dashboard', icon: 'mdi-view-dashboard', props: { to: { name: 'AdminDashboard' } } },
        ])
      }
      return items
    },
    userIsAdmin() {
      // If realUser is set, the logged in user is Admin
      return !!localStorage.getItem('realUser')
    },
    ...mapState(useNotificationStore, ['notifications']),
    ...mapState(useUserStore, { user: store => store }),
  },
  mounted() {
    useUserStore().fetchUser().catch(e => handleApiError(e, 'Fehler beim Laden der Benutzerdaten'))
    useEventStore().initialize().catch(e => handleApiError(e, 'Fehler beim Laden der Touren Daten'))
    usePersonStore().fetchPersons().catch(e => handleApiError(e, 'Fehler beim Laden der Personen Daten'))
  },
  methods: {
    logout() {
      useAuthStore().logout()
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
